.version p,
.version svg {
    display: inline;
    vertical-align: middle;
}

.version svg {
    margin-left: 5px;
}

.kiosk-info-card {
    /* Properties:
        - background-color,
        - box-shadow,
        - border-color,
        are defined directly in KioskDetail using MUI theme.
    */
    height: 100%;
    min-height: 155px;
    position: relative;
    border-style: none solid solid;
    border-width: 1px;
    text-align: left;
    padding: 15px 10px 10px;
    /* font-family: "Rawline Regular", sans-serif; */
}

.kiosk-info-card::before {
    content: "";
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    margin-left: -1px;
    position: absolute;
    height: 7px;
    background: linear-gradient(to left, #d92a55 50%, #202241 50%);
}

.kiosk-info-card .attributes {
    width: 100%;
}

.kiosk-info-card .attributes span {
    width: 100%;
    font-size: 0.875rem;
    display: inline-flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;
}

.kiosk-card-icon {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 90px !important;
    height: 90px !important;
    /* Properties:
        - color,
        are defined directly in KioskDetail using MUI theme.
    */
    opacity: 0.075;
    z-index: 0;
}

.kiosk-info-card h4 {
    margin: 0 0 7px;
    /* Properties:
        - color,
        are defined directly in KioskDetail using MUI theme.
    */
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.125rem;
    /* font-family: "Rawline Semibold"; */
    grid-column: 1 / 3;
}

.kiosk-info-card p {
    margin: 0;
    padding: 0;
}

.kiosk-info-card p:nth-of-type(2n + 1) {
    font-weight: bold;
    font-family: "Rawline Medium", sans-serif;
    text-align: left;
}

.kiosk-info-card p:nth-of-type(2n) {
    font-family: "Rawline Regular", sans-serif;
    text-align: right;
}

.kiosk-info-card .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 15px;
}

.kiosk-info-card .additional-content {
    max-width: 100px;
    display: inline-block;
}

.kiosk-info-card .additional-content + .attributes {
    width: calc(100% - 100px);
}

.kiosk-option-buttons .MuiButton-root {
    width: 100%;
    height: 100%;
}

.local-side-menu div, .local-side-menu .MuiButton-root {
	width: 100%;
}

.kiosk-screenshot-wrapper {
	width: 100%;
	height: 100%;
}

.kiosk-screenshot-wrapper {
	position: relative;
}

.kiosk-screenshot-wrapper img {
	max-width: 100%;
    max-height: 100%;
}

.kiosk-screenshot-wrapper div {
    width: 100%;
    height: 100%;
}

.kiosk-info-card {
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
}