.chonky-chonkyRoot {
    min-height: calc(100vh - 330px);
    flex-grow: 1;
}

.file-explorer-dialog {
    min-width: min(800px, 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
}

.file-explorer-dialog > span {
    width: 100%;
    flex-grow: 1;
}

.file-explorer-dialog > .dialog-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.file-explorer-dialog > .dialog-buttons > button {
    margin: 0;
    flex-grow: 1;
}